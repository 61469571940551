import { css } from '@emotion/react'

import { codeTextStyles } from 'src/components/Text'
import COLOR from 'src/constants/color'

export const cardContainer = css`
  background: ${COLOR.WHITE_ALPHA_50};
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  border-radius: 12px;
  padding: 12px 24px 18px;
`

export const headingText = css`
  color: ${COLOR.WHITE_ALPHA_800};
  margin: 0 0 12px;
  font-size: 18px;
  font-weight: 600;
`

export const descriptionText = css`
  margin: 0 0 24px;
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
`

export const errorText = css`
  margin-top: 16px;
  color: ${COLOR.RED_500};
  font-size: 12px;
`

export const digitalDisplayStyle = [
  codeTextStyles,
  css`
    font-size: 32px;
    font-weight: 500;
    color: ${COLOR.WHITE};
    letter-spacing: -0.5px;
  `,
]
