import { useEffect, useState } from 'react';
import { TradeUserStatus } from 'src/features/trade/hooks/useTrade';
import useApi from 'src/hooks/useApi';
export default function useTradeOnboarding(_ref) { var _tradeUser$status; let { tradeUser, setTradeUser } = _ref; const [error, setError] = useState(null); const [initialLoading, setInitialLoading] = useState(false); const { fetch: fetchTradeUserDetails } = useApi({ serviceName: 'trade', path: 'tradeUsers/me', method: 'GET' }); const { fetch: fetchCreateTradeUserOnboardingToken } = useApi({ serviceName: 'trade', path: 'tradeUsers/me/onboardingToken', method: 'POST' }); const onGetToken = async () => { setInitialLoading(true); const createResponse = await fetchCreateTradeUserOnboardingToken(); if (createResponse.error != null) {
    setError('Unable to get onboarding token');
    setInitialLoading(false);
    return null;
} const fetchDetailsResponse = await fetchTradeUserDetails(); if (fetchDetailsResponse.error != null) {
    setError('Unable to fetch trade user details');
    setInitialLoading(false);
    return null;
} setInitialLoading(false); return createResponse.data; }; const checkTradeUser = async () => { if (tradeUser == null) {
    setError('Trade user not found');
    return;
} const fetchDetailsResponse = await fetchTradeUserDetails(); if (fetchDetailsResponse.error != null) {
    setError('Unable to fetch trade user details');
    return;
} setTradeUser(fetchDetailsResponse.data); }; const status = (_tradeUser$status = tradeUser === null || tradeUser === void 0 ? void 0 : tradeUser.status) !== null && _tradeUser$status !== void 0 ? _tradeUser$status : TradeUserStatus.NOT_STARTED; useEffect(() => { if (tradeUser != null) {
    return;
} void fetchTradeUserDetails().then(res => { if (res.error != null) {
    setError('Unable to fetch trade users');
    return;
} setTradeUser(res.data); }); }, [tradeUser, setTradeUser, error, fetchTradeUserDetails]); const canStartOnboarding = [TradeUserStatus.NOT_STARTED, TradeUserStatus.INCOMPLETE, TradeUserStatus.REJECTED].includes(status); return { initialLoading, error, status, onGetToken, checkTradeUser, tradeUser, canStartOnboarding }; }
