/* eslint-disable react/jsx-no-literals */
import { css } from '@emotion/react'
import { useState } from 'react'

import Button from 'src/components/Button'
import Spacer from 'src/components/Spacer'
import { fontFamilies, TextParagraph } from 'src/components/Text'
import COLOR from 'src/constants/color'
import { useTrade } from 'src/features/trade/hooks/useTrade'
import {
  Activity,
  ActivityStatus,
  ActivityType,
  FUND_ASSETS,
  FundOption,
} from 'src/features/trade/hooks/useTradeFunding'
import { useFormatDate, useFormatTime } from 'src/hooks/useTranslate'
import { ReactComponent as BackIcon } from 'src/images/chevron-left.svg'

/**
 * Constants for component text and configuration
 */
export const FUND_ACCOUNT_TEXT = {
  BUY_CRYPTO: 'Buy Crypto',
  TITLE: 'Step 2: Fund your account',
  TIP: 'Once KYC is complete, tradeUsers will have a ZeroHash participant ID, stored as "providerUserId" in the TradeUser table, and status of active. Next, a user must fund a ZeroHash account to begin buying or selling.',
  BACK_TEXT: 'Back',
  FUND_BUTTON_TEXT: 'Fund Account',
  NOT_IMPLEMENTED_TEXT: 'Not Implemented',
  SUCCESS_MESSAGE:
    'Funding request submitted. Funds may not appear instantly, especially if this is the first time funding for this currency, as ZeroHash must make a new currency account. Use the refresh button to check the status of your funding. In practice, this may be checked via polling for updates provided by ZeroHash webhooks.',
  AMOUNT_PLACEHOLDER: 'Enter amount',
  RECENT_ACTIVITY: 'Recent Activity',
  REFRESH_ACTIVITY: 'Refresh Activity',
  REFRESH_ACCOUNTS: 'Refresh Accounts',
  DEMO_OPTION: {
    TITLE: 'Send fake funds',
    DESCRIPTION:
      'Available for demo purposes only. This is the fastest way to set up your account.',
  },
  ACH_OPTION: {
    TITLE: 'Connect bank account',
    DESCRIPTION:
      'Powered by our partner ZeroHash. Set up your account to get the best rates on your crypto purchases',
  },
  WIRE_OPTION: {
    TITLE: 'Wire transfer',
    DESCRIPTION:
      "You'll be notified when your account is ready, usually within 24-48 hours.",
  },
  DEPOSIT: 'Deposited',
  PENDING: 'Pending',
  WITHDRAWAL: 'Withdrew',
  BUY: 'Bought',
  SELL: 'Sold',
} as const

const ACTIVITY_STATUS_DISPLAY = {
  [ActivityStatus.PENDING]: FUND_ACCOUNT_TEXT.PENDING,
  [ActivityStatus.COMPLETED]: null,
} as Record<ActivityStatus, string | null>

const ACTIVITY_TYPE_DISPLAY = {
  [ActivityType.DEPOSIT]: FUND_ACCOUNT_TEXT.DEPOSIT,
  [ActivityType.WITHDRAWAL]: FUND_ACCOUNT_TEXT.WITHDRAWAL,
  [ActivityType.BUY]: FUND_ACCOUNT_TEXT.BUY,
  [ActivityType.SELL]: FUND_ACCOUNT_TEXT.SELL,
} as Record<ActivityType, string>

const fundOptionsToText = {
  [FundOption.DEMO]: {
    title: FUND_ACCOUNT_TEXT.DEMO_OPTION.TITLE,
    description: FUND_ACCOUNT_TEXT.DEMO_OPTION.DESCRIPTION,
  },
  [FundOption.ACH]: {
    title: FUND_ACCOUNT_TEXT.ACH_OPTION.TITLE,
    description: FUND_ACCOUNT_TEXT.ACH_OPTION.DESCRIPTION,
  },
  [FundOption.WIRE]: {
    title: FUND_ACCOUNT_TEXT.WIRE_OPTION.TITLE,
    description: FUND_ACCOUNT_TEXT.WIRE_OPTION.DESCRIPTION,
  },
}

function FundOptionCard({
  option,
  title,
  description,
  isEnabled,
  onClick,
  children,
}: {
  option: FundOption
  title: string
  description: string
  isEnabled: boolean
  onClick: (option: FundOption) => void
  children?: React.ReactNode
}) {
  return (
    <div css={fundOptionCardContainer} onClick={() => onClick(option)}>
      <TextParagraph css={fundOptionTitle}>{title}</TextParagraph>
      <TextParagraph css={fundOptionDescription}>{description}</TextParagraph>
      {children}
    </div>
  )
}

export function ActivityList({
  activity,
  onRefresh,
  activityLoading,
}: {
  activity: Activity[]
  onRefresh: () => void
  activityLoading: boolean
}) {
  const formatDate = useFormatDate({
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
  const formatTime = useFormatTime()

  return (
    <div css={activityListContainer}>
      <div css={activityHeaderContainer}>
        <TextParagraph css={activityTitle}>
          {FUND_ACCOUNT_TEXT.RECENT_ACTIVITY}
        </TextParagraph>
        <Button
          size='small'
          variant='alternate-inverted'
          onClick={onRefresh}
          loading={activityLoading}
        >
          {FUND_ACCOUNT_TEXT.REFRESH_ACTIVITY}
        </Button>
      </div>
      {activity.map((item, index) => (
        <div key={index} css={activityItemContainer}>
          <div css={activityIconContainer}>
            {/* Add an icon here if needed */}
          </div>
          <div css={activityDetailsContainer}>
            <TextParagraph css={activityType}>
              {ACTIVITY_TYPE_DISPLAY[item.type]} {item.asset}
            </TextParagraph>
            <TextParagraph css={activityTimestamp}>
              {formatDate(item.timestamp)} • {formatTime(item.timestamp)}
            </TextParagraph>
          </div>
          <div css={activityAmountContainer}>
            <TextParagraph css={activityAmount}>{item.amount}</TextParagraph>
            {item.status !== ActivityStatus.COMPLETED && (
              <TextParagraph css={activityStatus}>
                {ACTIVITY_STATUS_DISPLAY[item.status]}
              </TextParagraph>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

/**
 * Renders the funding option selection cards
 */
function FundOptionsList({
  onOptionSelect,
}: {
  onOptionSelect: (option: FundOption) => void
}) {
  return (
    <div>
      {Object.entries(fundOptionsToText).map(
        ([option, { title, description }]) => (
          <FundOptionCard
            key={option}
            option={option as FundOption}
            title={title}
            description={description}
            isEnabled={option === FundOption.DEMO}
            onClick={onOptionSelect}
          />
        ),
      )}
    </div>
  )
}

/**
 * Renders the funding form for demo accounts
 */
function DemoFundingForm({
  amount,
  selectedAsset,
  onAmountChange,
  onAssetChange,
  onSubmit,
  loading,
  showSuccess,
}: {
  amount: string
  selectedAsset: string
  onAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onAssetChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onSubmit: () => void
  loading: boolean
  showSuccess: boolean
}) {
  return (
    <>
      <div css={inputContainer}>
        <input
          type='number'
          value={amount}
          onChange={onAmountChange}
          placeholder={FUND_ACCOUNT_TEXT.AMOUNT_PLACEHOLDER}
          css={numberInput}
        />
        <select
          value={selectedAsset}
          onChange={onAssetChange}
          css={assetSelect}
        >
          {FUND_ASSETS.map((asset) => (
            <option key={asset} value={asset}>
              {asset}
            </option>
          ))}
        </select>
      </div>
      <Button
        size='small'
        variant='alternate-inverted'
        onClick={onSubmit}
        loading={loading}
        css={actionButton}
      >
        {FUND_ACCOUNT_TEXT.FUND_BUTTON_TEXT}
      </Button>
      {showSuccess && (
        <TextParagraph css={successMessage}>
          {FUND_ACCOUNT_TEXT.SUCCESS_MESSAGE}
        </TextParagraph>
      )}
    </>
  )
}

export default function FundAccount() {
  const { fundingState } = useTrade()
  const {
    loading,
    error,
    fundOption,
    setFundOption,
    fundAccount,
    loadAccounts,
  } = fundingState
  const [isOptionsVisible, setIsOptionsVisible] = useState(true)
  const [selectedAsset, setSelectedAsset] = useState('USD')
  const [amount, setAmount] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)

  const handleFundOptionClick = (option: FundOption) => {
    setFundOption(option)
    setIsOptionsVisible(false)
    setShowSuccess(false)
  }

  const handleBackClick = () => {
    setFundOption(null)
    setIsOptionsVisible(true)
    setShowSuccess(false)
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value)
    setShowSuccess(false)
  }

  const handleAssetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAsset(e.target.value)
    setShowSuccess(false)
  }

  const handleFundAccount = async () => {
    await fundAccount({ asset: selectedAsset, amount })
    setAmount('')
    setShowSuccess(true)
  }

  return (
    <div css={fundAccountContainer}>
      <div css={fundAccountContent}>
        {isOptionsVisible ? (
          <FundOptionsList onOptionSelect={handleFundOptionClick} />
        ) : (
          <>
            <div css={backButtonContainer} onClick={handleBackClick}>
              <BackIcon css={backIcon} />
              <TextParagraph css={backText}>
                {FUND_ACCOUNT_TEXT.BACK_TEXT}
              </TextParagraph>
            </div>
            {fundOption != null && (
              <FundOptionCard
                option={fundOption}
                title={fundOptionsToText[fundOption].title}
                description={fundOptionsToText[fundOption].description}
                isEnabled={fundOption === FundOption.DEMO}
                onClick={handleFundOptionClick}
              >
                <Spacer unit={4} />
                {fundOption === FundOption.DEMO && (
                  <DemoFundingForm
                    amount={amount}
                    selectedAsset={selectedAsset}
                    onAmountChange={handleAmountChange}
                    onAssetChange={handleAssetChange}
                    onSubmit={handleFundAccount}
                    loading={loading}
                    showSuccess={showSuccess}
                  />
                )}
                {fundOption !== FundOption.DEMO && (
                  <Button
                    size='small'
                    variant='alternate-inverted'
                    onClick={handleFundAccount}
                    loading={loading}
                    disabled={true}
                    css={css`
                      margin-right: 12px;
                    `}
                  >
                    {FUND_ACCOUNT_TEXT.NOT_IMPLEMENTED_TEXT}
                  </Button>
                )}
              </FundOptionCard>
            )}
          </>
        )}
        {error != null && (
          <TextParagraph css={errorText}>{error}</TextParagraph>
        )}
      </div>
      <div css={headerContainer}>
        <Button
          size='small'
          variant='alternate-inverted'
          onClick={() => loadAccounts()}
          css={refreshButton}
        >
          {FUND_ACCOUNT_TEXT.REFRESH_ACCOUNTS}
        </Button>
      </div>
    </div>
  )
}

const inputContainer = css`
  margin-bottom: 16px;
  display: flex;
  gap: 12px;
  align-items: center;
`

const numberInput = css`
  flex: 1;
  height: 40px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  background: transparent;
  color: ${COLOR.WHITE_ALPHA_800};
  font-family: ${fontFamilies};

  &::placeholder {
    color: ${COLOR.WHITE_ALPHA_400};
    font-family: ${fontFamilies};
  }
`

const assetSelect = css`
  height: 40px;
  padding: 8px 32px 8px 12px;
  border-radius: 4px;
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  background: transparent;
  color: ${COLOR.WHITE_ALPHA_800};
  font-family: ${fontFamilies};
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='4' viewBox='0 0 8 4'%3E%3Cpath fill='%23ffffff' d='M0 0l4 4 4-4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
`

const actionButton = css`
  margin-right: 12px;
`

const successMessage = css`
  margin-top: 16px;
  color: ${COLOR.GREEN_500};
  font-size: 14px;
`

const fundAccountContainer = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const fundAccountContent = css`
  flex: 1;
`

const backButtonContainer = css`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
`

const backIcon = css`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: ${COLOR.WHITE_ALPHA_800};
`

const backText = css`
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 14px;
  margin: 0;
`

const fundOptionCardContainer = css`
  background: ${COLOR.WHITE_ALPHA_50};
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${COLOR.WHITE_ALPHA_100};
  }
`

const fundOptionTitle = css`
  color: ${COLOR.WHITE_ALPHA_800};
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: 600;
`

const fundOptionDescription = css`
  margin: 0;
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
`

const activityListContainer = css`
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid ${COLOR.WHITE_ALPHA_100};
`

const activityHeaderContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const activityTitle = css`
  margin: 0;
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 16px;
  font-weight: 600;
`

const activityItemContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid ${COLOR.WHITE_ALPHA_50};

  &:last-child {
    border-bottom: none;
  }
`

const activityIconContainer = css`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${COLOR.WHITE_ALPHA_100};
  margin-right: 12px;
`

const activityDetailsContainer = css`
  flex: 1;
`

const activityType = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  font-weight: 500;
`

const activityAmountContainer = css`
  text-align: right;
`

const activityAmount = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  font-weight: 500;
`

const activityStatus = css`
  color: ${COLOR.YELLOW_500};
  font-size: 12px;
  margin-top: 4px;
`

const activityTimestamp = css`
  margin: 0;
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
`

const errorText = css`
  margin-top: 16px;
  color: ${COLOR.RED_500};
  font-size: 12px;
`

const headerContainer = css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`

const refreshButton = css`
  min-width: 100px;
`
