import { css } from '@emotion/react'
import { useCallback, useEffect, useState } from 'react'

import Button from 'src/components/Button'
import Spacer from 'src/components/Spacer'
import { fontFamilies, TextParagraph } from 'src/components/Text'
import COLOR from 'src/constants/color'
import { useTrade } from 'src/features/trade/hooks/useTrade'
import {
  Estimate,
  TRADE_CONSTANTS,
  TradeOrderSide,
  TradeQuote,
} from 'src/features/trade/hooks/useTradeBuy'
import { CRYPTO_ASSETS } from 'src/features/trade/hooks/useTradeFunding'
import { digitalDisplayStyle } from 'src/features/trade/styles/DemoStyles'
import { ReactComponent as ReloadIcon } from 'src/images/transaction-icon-rotate.svg'

/**
 * Strings for the Trade Buy page, held in an object to allow for easy
 * access, but should be replaced with i18n strings in a non-demo environment.
 */
export const TRADE_BUY_TEXT = {
  TITLE: 'Step 3: Execute Trade',
  TIPS: [
    'Select an asset, and modify the input amount to create an estimate that refreshes on text entry change. You can also toggle the input mode to switch between fiat and crypto. Once the input amount is complete, click "Review Order" to get a binding quote that expires in 5 seconds. Click "Confirm Order" to finalize the trade.',
  ],
  DESCRIPTION: 'Enter the amount you want to buy',
  BACK: 'Back',
  ENTER_AMOUNT: 'Enter amount',
  REVIEW_ORDER: 'Review Order',
  CONFIRM_ORDER: 'Confirm Order',
  MODIFY_ORDER: 'Modify Order',
  MARKET_DATA_LOADING: 'Loading market data...',
  LOADING_ESTIMATE: 'Loading estimate...',
  LOADING_QUOTE: 'Loading quote...',
  PRICE: 'Price',
  FEE: 'Fee',
  TOTAL: 'Total',
  EXPIRES_IN: 'Quote expires in',
  MARKET_DATA: {
    PRICE: 'Current Price',
    CHANGE_24H: '24h Change',
  },
  ERROR: {
    MARKET_DATA: 'Unable to load market data',
    ESTIMATE: 'Unable to get price estimate',
    QUOTE: 'Unable to get trade quote',
    EXECUTE: 'Unable to execute trade',
  },
  DEV_NOTES: {
    MARKET_DATA:
      'Real-time market data is fetched from the Trade API to show current crypto price and 24h change. The Trade API may use placeholder values until the crypto-price service is ready.',
    ESTIMATE:
      'When an amount is entered, the API provides an estimate based on the current index price provided by ZeroHash. It must be converted to a quote provided by ZeroHash before it can be executed.',
    QUOTE:
      'Clicking "Review Order" gets a binding quote that expires in 5 seconds.',
    EXECUTE: 'The quote can be executed to finalize a trade order',
  },
  INPUT_AMOUNT: 'Amount',
  TRANSACTION_FEE: 'Transaction Fee',
  RECEIVE_AMOUNT: 'Receive',
  ORDER_TYPE: 'ORDER TYPE',
  BTC_PRICE: 'BTC PRICE',
  AMOUNT: 'AMOUNT',
  RECEIVE: 'RECEIVE',
  WARNING_ICON: '⚠️',
  FUNDING_REQUIRED: 'Additional funding required to complete this trade',
  DISCARD_QUOTE: 'Discard',
  EXECUTE_QUOTE: 'Execute',
  EXPIRED: 'Expired',
}

/**
 * Trading Interface Component Architecture
 * =====================================
 *
 * Component Hierarchy and Data Flow
 * -------------------------------
 * TradeBuy (Parent)
 *   └─ SelectBuyAsset
 *      └─ MarketDataDisplay
 *   └─ TradeBuyForm
 *      └─ EstimateSummary
 *         └─ OrderDetails
 *         └─ CountdownTimer
 *
 * Each component handles a specific part of the trading flow:
 * 1. Asset Selection -> Market Data -> Price Display
 * 2. Amount Input -> Estimate -> Quote -> Execution
 */
const MS_PER_SECOND = 1000
const formatTimeRemaining = (ms: number) => {
  const seconds = Math.max(0, Math.floor(ms / MS_PER_SECOND))
  return `${seconds}s`
}

/**
 * CountdownTimer Component
 * -----------------------
 * Purpose: Displays countdown for time-sensitive operations
 * Used For:
 * - Estimate refresh countdown (30s)
 * - Quote expiration countdown (5s)
 *
 * @param expiryTime - When the timer should reach zero
 * @param refreshInterval - How often to update the display
 * @param label - Text to show with the countdown
 */
function CountdownTimer({
  expiryTime,

  label,
}: {
  expiryTime: Date
  label: string
}) {
  const [timeRemaining, setTimeRemaining] = useState<number>(0)

  useEffect(() => {
    const calculateRemaining = () => {
      const remaining = new Date(expiryTime).getTime() - Date.now()
      setTimeRemaining(remaining)
    }

    calculateRemaining()
    const interval = setInterval(calculateRemaining, MS_PER_SECOND)
    return () => clearInterval(interval)
  }, [expiryTime])

  return (
    <TextParagraph css={[countdownText, timeRemaining <= 0 && expiredText]}>
      {timeRemaining <= 0
        ? `${label} ${TRADE_BUY_TEXT.EXPIRED}`
        : `${label} ${formatTimeRemaining(timeRemaining)}`}
    </TextParagraph>
  )
}

/**
 * MarketDataDisplay Component
 * --------------------------
 * Purpose: Shows current market prices and 24h changes
 * Data Source: Market Data API via useTrade hook
 * Updates: Automatically on asset change
 *
 * Display Fields:
 * - Current Price (formatted with currency)
 * - 24h Price Change (formatted as percentage)
 */
function MarketDataDisplay() {
  const { buyState } = useTrade()
  const { marketData } = buyState

  if (marketData == null) {
    return null
  }

  return (
    <div css={marketDataContainer}>
      <div css={marketDataRow}>
        <TextParagraph css={labelText}>
          {TRADE_BUY_TEXT.MARKET_DATA.PRICE}
        </TextParagraph>
        <TextParagraph css={marketDataValue}>
          {
            marketData[buyState.asset][TRADE_CONSTANTS.FIAT_BASIS]?.price
              .formatted
          }
        </TextParagraph>
      </div>
      <div css={marketDataRow}>
        <TextParagraph css={labelText}>
          {TRADE_BUY_TEXT.MARKET_DATA.CHANGE_24H}
        </TextParagraph>
        <TextParagraph css={marketDataValue}>
          {
            marketData[buyState.asset][TRADE_CONSTANTS.FIAT_BASIS]?.dayPctChange
              .formatted
          }
        </TextParagraph>
      </div>
      <TextParagraph css={devNote}>
        {TRADE_BUY_TEXT.DEV_NOTES.MARKET_DATA}
      </TextParagraph>
    </div>
  )
}

/**
 * OrderDetails Component
 * ---------------------
 * Purpose: Displays trade details for estimates and quotes
 * Used In: Both estimate preview and final quote review
 *
 * Display Fields:
 * - Order Type (BUY/SELL)
 * - Asset Price
 * - Transaction Amount
 * - Fees
 * - Final Receive Amount
 */
function OrderDetails({ order }: { order: Estimate | TradeQuote }) {
  return (
    <>
      <div css={summaryRow}>
        <TextParagraph css={summaryLabel}>
          {TRADE_BUY_TEXT.ORDER_TYPE}
        </TextParagraph>
        <TextParagraph css={summaryValue}>
          {order.orderType.toUpperCase()}
        </TextParagraph>
      </div>

      <div css={summaryRow}>
        <TextParagraph css={summaryLabel}>
          {TRADE_BUY_TEXT.BTC_PRICE}
        </TextParagraph>
        <TextParagraph css={summaryValue}>
          {order.indexPrice.formatted}
        </TextParagraph>
      </div>

      <div css={summaryRow}>
        <TextParagraph css={summaryLabel}>
          {TRADE_BUY_TEXT.AMOUNT}
        </TextParagraph>
        <TextParagraph css={summaryValue}>
          {order.spendAmount.formatted}
        </TextParagraph>
      </div>

      <div css={summaryRow}>
        <TextParagraph css={summaryLabel}>
          {TRADE_BUY_TEXT.TRANSACTION_FEE}
        </TextParagraph>
        <TextParagraph css={summaryValue}>
          {order.transactionFee.formatted}
        </TextParagraph>
      </div>

      <div css={summaryRow}>
        <TextParagraph css={summaryLabel}>
          {TRADE_BUY_TEXT.RECEIVE}
        </TextParagraph>
        <TextParagraph css={[summaryValue, receiveValue]}>
          {order.receiveAmount.formatted}
        </TextParagraph>
      </div>
    </>
  )
}

/**
 * EstimateSummary Component
 * ------------------------
 * Purpose: Manages the display and state transitions for trade estimates/quotes
 *
 * States:
 * 1. Loading - Shows spinner during API calls
 * 2. Error - Displays error messages
 * 3. Estimate - Shows non-binding price calculation
 * 4. Quote - Shows binding trade quote with countdown
 *
 * Features:
 * - Automatic refresh of estimates
 * - Quote expiration handling
 * - Restriction warnings
 * - Funding requirement notices
 *
 * @param amount - Input amount (fiat or crypto)
 * @param asset - Selected cryptocurrency
 * @param onModify - Callback to edit trade
 * @param onConfirm - Callback to proceed with trade
 */
function EstimateSummary({
  amount,
  asset,
  onModify,
  onConfirm,
}: {
  amount: string
  asset: string
  onModify: () => void
  onConfirm: () => void
}) {
  const { buyState } = useTrade()
  const { estimate, quote, loading, error, quoteExpired, discardQuote } =
    buyState

  const handleModify = () => {
    discardQuote()
    onModify()
  }

  if (loading) {
    return (
      <div css={orderSummaryContainer}>
        <div css={loadingContainer}>
          <div css={spinner} />
          <TextParagraph css={labelText}>
            {quote
              ? TRADE_BUY_TEXT.LOADING_QUOTE
              : TRADE_BUY_TEXT.LOADING_ESTIMATE}
          </TextParagraph>
        </div>
      </div>
    )
  }

  if (error != null) {
    return <TextParagraph css={errorText}>{error}</TextParagraph>
  }

  if (quote) {
    return (
      <div css={orderSummaryContainer}>
        <OrderDetails order={quote} />
        <CountdownTimer
          expiryTime={quote.quoteExpiresAt}
          label='Quote expires in'
        />
        <div css={buttonContainer}>
          <Button
            size='small'
            variant='alternate-inverted'
            onClick={handleModify}
          >
            {TRADE_BUY_TEXT.DISCARD_QUOTE}
          </Button>
          <Button
            size='small'
            variant='alternate-inverted'
            onClick={onConfirm}
            loading={loading}
            disabled={quoteExpired}
          >
            {TRADE_BUY_TEXT.EXECUTE_QUOTE}
          </Button>
        </div>
      </div>
    )
  }

  if (estimate == null) {
    return null
  }

  const { restrictions, requiresFunding } = estimate

  return (
    <div css={orderSummaryContainer}>
      <OrderDetails order={estimate} />

      {/* Display Restrictions */}
      {restrictions.length > 0 && (
        <div css={restrictionsContainer}>
          {restrictions.map((restriction, index) => (
            <TextParagraph key={index} css={restrictionText}>
              {TRADE_BUY_TEXT.WARNING_ICON} {restriction.message}
            </TextParagraph>
          ))}
        </div>
      )}

      {/* Display Funding Required */}
      {requiresFunding && (
        <TextParagraph css={fundingRequiredText}>
          {TRADE_BUY_TEXT.FUNDING_REQUIRED}
        </TextParagraph>
      )}

      <CountdownTimer
        expiryTime={new Date(Date.now() + TRADE_CONSTANTS.ESTIMATE_REFRESH_MS)}
        label='Estimate refreshes in'
      />

      <div css={buttonContainer}>
        <Button
          size='small'
          variant='alternate-inverted'
          onClick={onConfirm}
          loading={loading}
          disabled={restrictions.length > 0 || requiresFunding}
        >
          {TRADE_BUY_TEXT.REVIEW_ORDER}
        </Button>
      </div>

      <TextParagraph css={devNote}>
        {TRADE_BUY_TEXT.DEV_NOTES.ESTIMATE}
      </TextParagraph>
    </div>
  )
}

/**
 * SelectBuyAsset Component
 * -----------------------
 * Purpose: Manages cryptocurrency selection and displays market data
 *
 * Features:
 * - Asset selection buttons
 * - Current price display
 * - 24h price change
 * - Automatic market data updates
 */
export function SelectBuyAsset() {
  const { buyState } = useTrade()
  const { asset, setAsset } = buyState
  return (
    <>
      <div css={assetList}>
        {CRYPTO_ASSETS.map((assetOption) => (
          <button
            key={assetOption}
            css={[assetButton, asset === assetOption && selectedAssetButton]}
            onClick={() => setAsset(assetOption)}
          >
            {assetOption}
          </button>
        ))}
      </div>
      <Spacer unit={4} />
      <MarketDataDisplay />
    </>
  )
}

/**
 * TradeBuyForm Component
 * ---------------------
 * Purpose: Handles user input and trade flow progression
 *
 * Features:
 * - Amount input (with validation)
 * - Estimate/Quote display
 * - Order modification
 * - Trade execution
 *
 * State Management:
 * - Tracks input amount
 * - Manages order summary visibility
 * - Handles quote state
 */
const DEFAULT_INPUT_AMOUNT = '0.00'
const INPUT_MODE = {
  FIAT: 'FIAT',
  CRYPTO: 'CRYPTO',
} as const

type InputMode = typeof INPUT_MODE[keyof typeof INPUT_MODE]

export function TradeBuyForm() {
  const [amount, setAmount] = useState(DEFAULT_INPUT_AMOUNT)
  const [showOrderSummary, setShowOrderSummary] = useState(false)
  const { buyState } = useTrade()
  const { asset, quote, getQuote, executeQuote } = buyState

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = e.target.value
    setAmount(newAmount)

    if (
      newAmount === DEFAULT_INPUT_AMOUNT ||
      newAmount === '' ||
      Number(newAmount) === 0
    ) {
      return
    }

    void getQuote({
      side: TradeOrderSide.BUY,
      spendAsset: TRADE_CONSTANTS.FIAT_BASIS,
      receiveAsset: asset,
      spendQuantity: Number(newAmount),
    })
  }

  const handleGetQuote = useCallback(async () => {
    await getQuote({
      side: TradeOrderSide.BUY,
      spendAsset: TRADE_CONSTANTS.FIAT_BASIS,
      receiveAsset: asset,
      spendQuantity: Number(amount),
    })
  }, [amount, asset, getQuote])

  const handleExecuteQuote = useCallback(async () => {
    if (quote?.id == null) {
      return
    }

    await executeQuote()
    setShowOrderSummary(false)
    setAmount(DEFAULT_INPUT_AMOUNT)
  }, [quote, executeQuote])

  return (
    <div css={formContainer}>
      <div css={inputContainer}>
        <input
          type='number'
          value={amount}
          onChange={handleAmountChange}
          placeholder={TRADE_BUY_TEXT.ENTER_AMOUNT}
          css={numberInput}
          disabled={showOrderSummary}
        />
        <div css={assetDisplay}>{asset}</div>
      </div>

      {amount !== '' && (
        <EstimateSummary
          amount={amount}
          asset={asset}
          onModify={() => setShowOrderSummary(false)}
          onConfirm={quote ? handleExecuteQuote : handleGetQuote}
        />
      )}
    </div>
  )
}

/**
 * TradeBuy Component (Main)
 * ------------------------
 * Purpose: Primary trading interface that coordinates all trading activities
 *
 * Features:
 * 1. Dual Input Modes
 *    - Fiat input (e.g., USD)
 *    - Crypto input (e.g., BTC)
 *
 * 2. Real-time Updates
 *    - Price estimates
 *    - Market data
 *    - Quote management
 *
 * 3. Trade Flow Management
 *    - Input validation
 *    - State transitions
 *    - Error handling
 *
 * Integration Points:
 * - Uses useTrade hook for backend communication
 * - Manages all trading state
 * - Coordinates UI updates
 *
 * Note: This component implements the UI layer for the trading system.
 * The core business logic is handled by the useTradeBuy hook.
 */
export function TradeBuy() {
  const [cryptoAmount, setCryptoAmount] = useState(DEFAULT_INPUT_AMOUNT)
  const [fiatAmount, setFiatAmount] = useState(DEFAULT_INPUT_AMOUNT)
  const [inputMode, setInputMode] = useState<InputMode>(INPUT_MODE.FIAT)
  const [showOrderSummary, setShowOrderSummary] = useState(false)
  const { buyState } = useTrade()
  const { asset, quote, estimate, getEstimate, getQuote, executeQuote } =
    buyState

  useEffect(() => {
    if (
      asset == null ||
      (fiatAmount === DEFAULT_INPUT_AMOUNT &&
        cryptoAmount === DEFAULT_INPUT_AMOUNT)
    ) {
      return
    }

    const estimateParams = {
      side: TradeOrderSide.BUY,
      spendAsset: TRADE_CONSTANTS.FIAT_BASIS,
      receiveAsset: asset,
      spendQuantity:
        inputMode === INPUT_MODE.FIAT ? Number(fiatAmount) : undefined,
      receiveQuantity:
        inputMode === INPUT_MODE.FIAT ? undefined : Number(cryptoAmount),
    }

    void getEstimate(estimateParams)
  }, [asset, fiatAmount, cryptoAmount, inputMode, getEstimate])

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = e.target.value

    if (inputMode === INPUT_MODE.FIAT) {
      setFiatAmount(newAmount)
    } else {
      setCryptoAmount(newAmount)
    }
  }

  const handleGetQuote = useCallback(async () => {
    if (asset == null || !estimate) {
      return
    }

    await getQuote({
      side: TradeOrderSide.BUY,
      spendAsset: TRADE_CONSTANTS.FIAT_BASIS,
      receiveAsset: asset,
      spendQuantity:
        inputMode === INPUT_MODE.FIAT ? Number(fiatAmount) : undefined,
      receiveQuantity:
        inputMode === INPUT_MODE.FIAT ? undefined : Number(cryptoAmount),
    })
  }, [fiatAmount, cryptoAmount, asset, getQuote, inputMode, estimate])

  const handleExecuteQuote = useCallback(async () => {
    if (quote?.id == null) {
      return
    }

    await executeQuote()
    setShowOrderSummary(false)
    setFiatAmount(DEFAULT_INPUT_AMOUNT)
    setCryptoAmount(DEFAULT_INPUT_AMOUNT)
  }, [quote, executeQuote])

  const handleSwitchInputMode = () => {
    if (estimate != null) {
      setCryptoAmount(Number(estimate.receiveAmount.raw).toFixed(8).toString())
      setFiatAmount(Number(estimate.spendAmount.raw).toFixed(2).toString())
    }

    setInputMode((mode) =>
      mode === INPUT_MODE.FIAT ? INPUT_MODE.CRYPTO : INPUT_MODE.FIAT,
    )
  }

  return (
    <div css={previewCard}>
      <div css={inputContainer}>
        <div css={primaryInputWrapper}>
          <input
            type='number'
            value={inputMode === INPUT_MODE.FIAT ? fiatAmount : cryptoAmount}
            onChange={handleAmountChange}
            placeholder={TRADE_BUY_TEXT.ENTER_AMOUNT}
            css={numberInput}
            disabled={showOrderSummary}
          />
          <TextParagraph css={primaryAssetDisplay}>
            {inputMode === INPUT_MODE.FIAT ? TRADE_CONSTANTS.FIAT_BASIS : asset}
          </TextParagraph>
          <button css={switchButton} onClick={handleSwitchInputMode}>
            <ReloadIcon css={reloadIcon} />
          </button>
        </div>
        <div css={secondaryAmountDisplay}>
          {inputMode === INPUT_MODE.FIAT
            ? estimate?.receiveAmount.formatted
            : estimate?.spendAmount.formatted}
        </div>
      </div>
      <EstimateSummary
        amount={fiatAmount}
        asset={asset}
        onModify={() => setShowOrderSummary(false)}
        onConfirm={quote ? handleExecuteQuote : handleGetQuote}
      />
    </div>
  )
}

const previewCard = css`
  max-width: 400px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: ${COLOR.WHITE_ALPHA_50};
  min-height: 600px;
`

const inputContainer = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  margin-bottom: 24px;
  &,
  & * {
    font-family: ${fontFamilies};
  }
`

const numberInput = [
  digitalDisplayStyle,
  css`
    flex: 1;
    height: 40px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid ${COLOR.WHITE_ALPHA_100};
    color: ${COLOR.WHITE};
    background: transparent;
    font-size: 18px;
    text-align: right;
    max-width: 60%;

    &::placeholder {
      color: ${COLOR.WHITE_ALPHA_400};
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `,
]

const marketDataContainer = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background: ${COLOR.WHITE_ALPHA_50};
  border-radius: 8px;
  margin-bottom: 24px;
`

const marketDataRow = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const labelText = css`
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
`

const marketDataValue = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  font-weight: 500;
`

const orderSummaryContainer = css`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 24px;
  background: ${COLOR.BLACK};
  border-radius: 12px;
  margin-top: 24px;
`

const buttonContainer = css`
  display: flex;
  gap: 12px;
  margin-top: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const errorText = css`
  color: ${COLOR.RED_500};
  font-size: 14px;
`

const devNote = css`
  color: ${COLOR.PURPLE_100};
  font-size: 14px;
  margin-top: 12px;
  font-style: italic;
`

const assetList = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  width: 100%;
`

const assetButton = css`
  padding: 12px;
  border-radius: 8px;
  width: 22%;
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  background: transparent;
  color: ${COLOR.WHITE};
  font-family: ${fontFamilies};
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${COLOR.WHITE_ALPHA_50};
  }
`

const selectedAssetButton = css`
  background: ${COLOR.WHITE_ALPHA_100};
  border-color: ${COLOR.WHITE};
`

const formContainer = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: ${COLOR.WHITE_ALPHA_50};
  border-radius: 12px;
`

const assetDisplay = css`
  padding: 8px 16px;
  background: ${COLOR.WHITE_ALPHA_100};
  border-radius: 4px;
  color: ${COLOR.WHITE};
  font-weight: 500;
`

const primaryInputWrapper = css`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
`

const primaryAssetDisplay = [
  digitalDisplayStyle,
  css`
    padding: 0;
    border-radius: 4px;
    color: ${COLOR.WHITE};
    font-size: 18px;
    text-align: left;
  `,
]

const secondaryAmountDisplay = [
  digitalDisplayStyle,
  css`
    color: ${COLOR.PURPLE_400};
    font-size: 18px;
    margin-top: 4px;
  `,
]

const switchButton = css`
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: ${COLOR.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.2s;

  &:hover {
    background: ${COLOR.WHITE_ALPHA_100};
  }
`

const reloadIcon = css`
  width: 24px;
  height: 24px;
  color: ${COLOR.WHITE};
  path {
    fill: ${COLOR.WHITE};
  }
`

const loadingContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px;
`

const spinner = css`
  width: 24px;
  height: 24px;
  border: 2px solid ${COLOR.WHITE_ALPHA_100};
  border-top-color: ${COLOR.WHITE};
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

const restrictionsContainer = css`
  margin-top: 16px;
  padding: 12px;
  background: ${COLOR.RED_500}20;
  border-radius: 8px;
`

const restrictionText = css`
  color: ${COLOR.RED_500};
  font-size: 14px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

const fundingRequiredText = css`
  color: ${COLOR.YELLOW_500};
  font-size: 14px;
  text-align: center;
  margin-top: 12px;
  padding: 8px;
  background: ${COLOR.YELLOW_500}20;
  border-radius: 4px;
`

const summaryRow = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid ${COLOR.WHITE_ALPHA_100};

  &:last-child {
    border-bottom: none;
  }
`

const summaryLabel = css`
  text-transform: uppercase;
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
  font-family: ${fontFamilies};
`

const summaryValue = css`
  color: ${COLOR.WHITE};
  font-size: 16px;
  font-family: ${fontFamilies};
  text-align: right;
`

const receiveValue = css`
  display: block;
`

const expiredText = css`
  color: ${COLOR.RED_500};
`

const countdownText = css`
  color: ${COLOR.WHITE_ALPHA_400};
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
`
