import { TextParagraph } from 'src/components/Text'
import {
  cardContainer,
  headingText,
  descriptionText,
} from 'src/features/trade/styles/DemoStyles'

interface TradeCardProps {
  title: string
  description: string
  children?: React.ReactNode
}

/**
 * A standardized card component used throughout the trade interface
 * Provides consistent styling for title, description, and content areas
 */
export function TradeCard({ title, description, children }: TradeCardProps) {
  return (
    <div css={cardContainer}>
      <TextParagraph css={headingText}>{title}</TextParagraph>
      <TextParagraph css={descriptionText}>{description}</TextParagraph>
      {children}
    </div>
  )
}
