import { css } from '@emotion/react'
import { useState, useCallback } from 'react'

import { TextParagraph } from 'src/components/Text'
import COLOR from 'src/constants/color'
import { ActivityList } from 'src/features/trade/components/FundAccount'
import { useTrade } from 'src/features/trade/hooks/useTrade'
import {
  CRYPTO_ASSETS,
  FIAT_ASSETS,
} from 'src/features/trade/hooks/useTradeFunding'
import { digitalDisplayStyle } from 'src/features/trade/styles/DemoStyles'
import { ReactComponent as BackIcon } from 'src/images/chevron-left.svg'

export const VIEW_ACCOUNT_TEXT = {
  TITLE: 'Your Accounts',
  TOTAL_VALUE: 'Total Portfolio Value',
  CASH_ACCOUNTS: 'Cash Accounts',
  CRYPTO_ACCOUNTS: 'Crypto Accounts',
  LOADING: 'Loading accounts...',
  ERROR: 'Unable to load accounts',
  NO_ACCOUNTS: 'No accounts found',
  POWERED_BY: 'POWERED BY ZEROHASH',
  TOTAL_BALANCE: 'Total Balance',
  BACK: 'Back',
  BALANCE: 'Balance',
} as const

export const CURRENCY_CONSTANTS = {
  DEFAULT_FIAT: 'USD',
  DEFAULT_AMOUNT: '$0.00',
} as const

function AccountRow({
  asset,
  balance,
  fiatValue,
  onClick,
}: {
  asset: string
  balance: string
  fiatValue: string
  onClick?: () => void
}) {
  return (
    <div css={[accountRowContainer, onClick && clickableRow]} onClick={onClick}>
      <div css={assetContainer}>
        <div
          css={[
            assetIcon,
            FIAT_ASSETS.includes(asset) && cashIcon,
            CRYPTO_ASSETS.includes(asset) && btcIcon,
          ]}
        />
        <div>
          <TextParagraph css={assetText}>{asset}</TextParagraph>
        </div>
      </div>
      <div css={balanceContainer}>
        <TextParagraph css={balanceText}>{balance}</TextParagraph>
        {asset !== CURRENCY_CONSTANTS.DEFAULT_FIAT && (
          <TextParagraph css={fiatBalanceText}>{fiatValue}</TextParagraph>
        )}
      </div>
    </div>
  )
}

function CurrencyDetail({
  asset,
  balance,
  fiatValue,
  onBack,
  children,
}: {
  asset: string
  balance: string
  fiatValue: string
  onBack: () => void
  children: React.ReactNode
}) {
  return (
    <div css={container}>
      <div css={backButtonContainer} onClick={onBack}>
        <BackIcon css={backIcon} />
        <TextParagraph css={backText}>{VIEW_ACCOUNT_TEXT.BACK}</TextParagraph>
      </div>
      <TextParagraph css={currencyTitle}>
        {VIEW_ACCOUNT_TEXT.BALANCE} {asset}
      </TextParagraph>
      <div css={digitalBalanceContainer}>
        <TextParagraph css={digitalDisplayStyle}>{balance}</TextParagraph>
        {asset !== CURRENCY_CONSTANTS.DEFAULT_FIAT && (
          <TextParagraph css={subBalance}>{fiatValue}</TextParagraph>
        )}
      </div>
      {children}
    </div>
  )
}

export default function ViewAccount() {
  const { fundingState } = useTrade()
  const { accounts, loading, error, summary, loadActivity } = fundingState
  const [selectedAsset, setSelectedAsset] = useState<string | null>(null)

  const handleAccountClick = useCallback(
    async (asset: string) => {
      setSelectedAsset(asset === selectedAsset ? null : asset)
      if (asset !== selectedAsset) {
        await loadActivity(asset)
      }
    },
    [selectedAsset, loadActivity],
  )

  if (loading) {
    return <TextParagraph>{VIEW_ACCOUNT_TEXT.LOADING}</TextParagraph>
  }

  if (error != null) {
    return (
      <TextParagraph css={errorText}>{VIEW_ACCOUNT_TEXT.ERROR}</TextParagraph>
    )
  }

  if (accounts.length === 0) {
    return <TextParagraph>{VIEW_ACCOUNT_TEXT.NO_ACCOUNTS}</TextParagraph>
  }

  const portfolioTotal =
    summary?.[CURRENCY_CONSTANTS.DEFAULT_FIAT]?.total ??
    CURRENCY_CONSTANTS.DEFAULT_AMOUNT

  const cashAccounts = accounts
    .filter((account) => FIAT_ASSETS.includes(account.asset))
    // Sort the default fiat asset to the top
    .sort((a, b) => (a.asset === CURRENCY_CONSTANTS.DEFAULT_FIAT ? -1 : 1))

  const cryptoAccounts = accounts
    .filter((account) => CRYPTO_ASSETS.includes(account.asset))
    // Sort the default fiat asset to the top
    .sort((a, b) => (a.asset === CURRENCY_CONSTANTS.DEFAULT_FIAT ? -1 : 1))

  if (selectedAsset != null) {
    const account = accounts.find((a) => a.asset === selectedAsset)
    if (account == null) {
      return null
    }

    return (
      <CurrencyDetail
        asset={account.asset}
        balance={account.balance}
        fiatValue={account.fiat[CURRENCY_CONSTANTS.DEFAULT_FIAT].total}
        onBack={() => setSelectedAsset(null)}
      >
        <ActivityList
          activityLoading={fundingState.activityLoading}
          activity={fundingState.activity}
          onRefresh={() => loadActivity(account.asset)}
        />
      </CurrencyDetail>
    )
  }

  return (
    <div css={container}>
      <div css={digitalBalanceContainer}>
        <TextParagraph css={balanceLabel}>
          {VIEW_ACCOUNT_TEXT.TOTAL_BALANCE}
        </TextParagraph>
        <TextParagraph css={digitalDisplayStyle}>
          {portfolioTotal}
        </TextParagraph>
        <TextParagraph css={poweredByText}>
          {VIEW_ACCOUNT_TEXT.POWERED_BY}
        </TextParagraph>
      </div>
      {cashAccounts.length > 0 && (
        <div css={accountsSection}>
          <TextParagraph css={sectionHeader}>
            {VIEW_ACCOUNT_TEXT.CASH_ACCOUNTS}
          </TextParagraph>
          <div css={cashAccountsContainer}>
            {cashAccounts.map((account) => (
              <AccountRow
                key={account.asset}
                asset={account.asset}
                balance={account.balance}
                fiatValue={account.fiat[CURRENCY_CONSTANTS.DEFAULT_FIAT].total}
                onClick={() => handleAccountClick(account.asset)}
              />
            ))}
          </div>
        </div>
      )}

      {/* Crypto Accounts Section */}
      {cryptoAccounts.length > 0 && (
        <div css={accountsList}>
          {cryptoAccounts.map((account) => (
            <AccountRow
              key={account.asset}
              asset={account.asset}
              balance={account.balance}
              fiatValue={account.fiat[CURRENCY_CONSTANTS.DEFAULT_FIAT].total}
              onClick={() => handleAccountClick(account.asset)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const container = css`
  padding: 24px;
  background: ${COLOR.WHITE_ALPHA_50};
  border-radius: 12px;
`

export const totalValueAmount = css`
  color: ${COLOR.WHITE};
  font-size: 20px;
  font-weight: 600;
`

export const sectionTitle = css`
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16 px;
`

const accountRowContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid ${COLOR.WHITE_ALPHA_100};

  &:last-child {
    border-bottom: none;
  }
`

const assetText = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  font-weight: 500;
`

const balanceContainer = css`
  text-align: right;
`

const balanceText = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  font-weight: 500;
`

const errorText = css`
  color: ${COLOR.RED_500};
`

const digitalBalanceContainer = css`
  text-align: center;
  margin: 24px 0;
`

const balanceLabel = css`
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
  margin-bottom: 8px;
`

const poweredByText = css`
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 12px;
  margin-top: 8px;
`

const assetContainer = css`
  display: flex;
  align-items: center;
  gap: 12px;
`

const assetIcon = css`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${COLOR.WHITE_ALPHA_100};
`

const cashIcon = css`
  background: ${COLOR.BLUE_500};
`

const btcIcon = css`
  background: #ff9900;
`

const clickableRow = css`
  cursor: pointer;
  &:hover {
    background: ${COLOR.WHITE_ALPHA_50};
  }
`

const fiatBalanceText = css`
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
  margin-top: 4px;
`

const accountsList = css`
  background: ${COLOR.WHITE_ALPHA_50};
  border-radius: 12px;
  overflow: hidden;
`

const currencyTitle = css`
  color: ${COLOR.WHITE};
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 16px 0;
`

const subBalance = css`
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 14px;
  margin-top: 8px;
`

const backButtonContainer = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
`

const backIcon = css`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: ${COLOR.WHITE_ALPHA_800};
`

const backText = css`
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 14px;
  margin: 0;
`

const accountsSection = css`
  margin-bottom: 24px;
`

const cashAccountsContainer = css`
  background: ${COLOR.WHITE_ALPHA_50};
  border-radius: 12px;
  overflow: hidden;
  margin-top: 8px;
`

const sectionHeader = css`
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`
