import { createContext, useContext, ReactNode, useState } from 'react'

import useTradeBuy, {
  TradeBuyState,
} from 'src/features/trade/hooks/useTradeBuy'
import useTradeFunding, {
  TradeFundingState,
} from 'src/features/trade/hooks/useTradeFunding'
import useTradeOnboarding, {
  TradeOnboardingState,
} from 'src/features/trade/hooks/useTradeOnboarding'

export enum TradeUserStatus {
  NOT_STARTED = 'not_started',
  INCOMPLETE = 'incomplete',
  PENDING = 'pending',
  ACTIVE = 'active',
  REJECTED = 'rejected',
  SUSPENDED = 'suspended',
  CLOSED = 'closed',
}

export interface TradeUser {
  id: string
  email: string
  status: TradeUserStatus
  providerUserId?: string
}

interface TradeContextType {
  tradeUser: TradeUser | null
  setTradeUser: (user: TradeUser | null) => void
  fundingState: TradeFundingState
  onboardingState: TradeOnboardingState
  buyState: TradeBuyState
}

const TradeContext = createContext<TradeContextType | undefined>(undefined)

export function TradeProvider({ children }: { children: ReactNode }) {
  const [tradeUser, setTradeUser] = useState<TradeUser | null>(null)
  const onboardingState = useTradeOnboarding({ tradeUser, setTradeUser })
  const fundingState = useTradeFunding({ tradeUser })
  const buyState = useTradeBuy()

  return (
    <TradeContext.Provider
      value={{
        tradeUser,
        setTradeUser,
        onboardingState,
        fundingState,
        buyState,
      }}
    >
      {children}
    </TradeContext.Provider>
  )
}

export function useTrade() {
  const context = useContext(TradeContext)
  if (context === undefined) {
    throw new Error('useTradeContext must be used within a TradeProvider')
  }
  return context
}
