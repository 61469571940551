import { css } from '@emotion/react'
import { Redirect } from 'react-router-dom'

import Page from 'src/components/Page'
import Spacer from 'src/components/Spacer'
import { TextH1, TextSpan } from 'src/components/Text'
import COLOR from 'src/constants/color'
import ROUTE from 'src/constants/route'
import {
  DocumentationConfig,
  Steps,
  TradeStep,
} from 'src/features/trade/components/DemoStep'
import FundAccount, {
  FUND_ACCOUNT_TEXT,
} from 'src/features/trade/components/FundAccount'
import SetupAccount from 'src/features/trade/components/SetupAccount'
import {
  SETUP_ACCOUNT_TITLE,
  SETUP_ACCOUNT_TIPS,
} from 'src/features/trade/components/SetupAccount'
import {
  TradeBuy,
  SelectBuyAsset,
} from 'src/features/trade/components/TradeBuy'
import { TRADE_BUY_TEXT } from 'src/features/trade/components/TradeBuy'
import ViewAccount from 'src/features/trade/components/ViewAccount'
import { TradeProvider } from 'src/features/trade/hooks/useTrade'
import useFeatureFlags from 'src/hooks/useFeatureFlags'

const rightContentContainer = css`
  display: flex;
  gap: 24px;
`

const rightContentSection = css`
  flex: 1;
`

/**
 * Constants for the Buy Page
 */
const BUY_PAGE_CONSTANTS = {
  SPACER_UNITS: {
    TOP: 8,
    CONTENT: 4,
  },
  MAX_WIDTH: 1600,
  PADDING: 48,
} as const

/**
 * Renders the main content of the buy page, including setup and funding steps
 */
function BuyPageContent() {
  const { featureFlags, loading: featureFlagsLoading } = useFeatureFlags()

  /**
   * Util only, must be instantiated inside component to support dynamic styles.
   */
  const tradeSteps: TradeStep[] = [
    {
      title: SETUP_ACCOUNT_TITLE,
      leftContent: <SetupAccount />,
      rightContent: buildDocumentation({
        title: SETUP_ACCOUNT_TITLE,
        tips: SETUP_ACCOUNT_TIPS,
      }),
    },
    {
      title: FUND_ACCOUNT_TEXT.TITLE,
      leftContent: <FundAccount />,
      rightContent: (
        <div css={rightContentContainer}>
          <div css={rightContentSection}>
            {buildDocumentation({
              title: FUND_ACCOUNT_TEXT.TITLE,
              tips: [FUND_ACCOUNT_TEXT.TIP],
            })}
          </div>
          <div css={rightContentSection}>
            <ViewAccount />
          </div>
        </div>
      ),
    },
    {
      title: TRADE_BUY_TEXT.TITLE,
      leftContent: <SelectBuyAsset />,
      rightContent: (
        <div css={rightContentContainer}>
          <div css={rightContentSection}>
            {buildDocumentation({
              title: TRADE_BUY_TEXT.TITLE,
              tips: TRADE_BUY_TEXT.TIPS,
            })}
          </div>
          <div css={rightContentSection}>
            <TradeBuy />
          </div>
        </div>
      ),
    },
  ]

  if (!featureFlagsLoading && !featureFlags.enableWebBuySell) {
    return <Redirect to={ROUTE.HOME} />
  }

  return (
    <Page
      documentTitle={FUND_ACCOUNT_TEXT.BUY_CRYPTO}
      background={COLOR.BLACK}
      layout='full-page'
    >
      <div css={flowContainer}>
        <Spacer unit={BUY_PAGE_CONSTANTS.SPACER_UNITS.TOP} />
        <TextH1 css={pageText}>{FUND_ACCOUNT_TEXT.BUY_CRYPTO}</TextH1>
        <Spacer unit={BUY_PAGE_CONSTANTS.SPACER_UNITS.CONTENT} />
        <Steps steps={tradeSteps} />
      </div>
    </Page>
  )
}

/**
 * Builds the documentation section for a step
 */
function buildDocumentation({ title, tips }: DocumentationConfig) {
  return (
    <div>
      <TextH1 css={documentationTitle}>{title}</TextH1>
      {tips.map((tip, index) => (
        <TextSpan key={`${index}-tip`} css={documentationText}>
          {tip}
        </TextSpan>
      ))}
    </div>
  )
}

/**
 * Main Buy Page component wrapped with TradeProvider
 */
export default function BuyPage() {
  return (
    <TradeProvider>
      <BuyPageContent />
    </TradeProvider>
  )
}

const pageText = css`
  color: ${COLOR.WHITE};
`

const flowContainer = css`
  padding: ${BUY_PAGE_CONSTANTS.PADDING}px;
  max-width: ${BUY_PAGE_CONSTANTS.MAX_WIDTH}px;
  margin: 0 auto;
`

const documentationTitle = css`
  color: ${COLOR.WHITE};
  font-size: 20px;
  margin-bottom: 16px;
`

const documentationText = css`
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 8px;
  white-space: pre-line;

  a > span {
    color: ${COLOR.PURPLE_01};
  }

  ul,
  ol {
    margin-top: 8px;
  }

  li {
    margin-bottom: 4px;
    line-height: 1.4;
  }
`
