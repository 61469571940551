import { css } from '@emotion/react'

import { TextH1, TextSpan } from 'src/components/Text'
import COLOR from 'src/constants/color'

/**
 * Represents a single step in a multi-step trade process
 */
export interface TradeStep {
  disabled?: boolean
  leftContent: React.ReactNode
  rightContent?: React.ReactNode
  title: string
  description?: string
}

/**
 * Configuration for documentation display
 */
export interface DocumentationConfig {
  title: string
  tips: React.ReactNode[]
}

interface StepsProps {
  steps: TradeStep[]
}

/**
 * Renders the documentation section of a step
 */
export function StepDocumentation({
  title,
  tips,
}: {
  title: string
  tips: React.ReactNode[]
}) {
  return (
    <div>
      <TextH1 css={documentationTitle}>{title}</TextH1>
      {tips.map((tip, index) => (
        <TextSpan key={`tip-${index}`} css={documentationText}>
          {tip}
        </TextSpan>
      ))}
    </div>
  )
}

/**
 * Renders a single step in the trade process
 */
function Step({
  leftContent,
  rightContent,
}: Pick<TradeStep, 'leftContent' | 'rightContent'>) {
  return (
    <div css={stepRow}>
      <div css={leftColumn}>{leftContent}</div>
      {rightContent != null && <div css={rightColumn}>{rightContent}</div>}
    </div>
  )
}

/**
 * Steps component that renders a vertical list of trade process steps
 * Each step consists of a left content area (usually forms/interactions)
 * and an optional right content area (usually documentation)
 */
export function Steps({ steps }: StepsProps) {
  return (
    <div css={stepContainer}>
      {steps.map((step, index) => (
        <Step
          key={`step-${index}`}
          leftContent={step.leftContent}
          rightContent={step.rightContent}
        />
      ))}
    </div>
  )
}

const stepContainer = css`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: fit-content;
`

const stepRow = css`
  display: flex;
  position: relative;
  align-items: stretch;
  gap: 1rem;
  padding: 2rem;
  border: 1px solid ${COLOR.GRAY_800};
  background: ${COLOR.GRAY_900};
  border-radius: 16px;
  min-width: fit-content;
`

const leftColumn = css`
  flex: 0 0 400px;
  padding-right: 2rem;
`

const rightColumn = css`
  flex: 1;
  padding-left: 2rem;
  border-left: 1px solid ${COLOR.GRAY_800};
`

const documentationTitle = css`
  color: ${COLOR.WHITE};
  font-size: 20px;
  margin-bottom: 16px;
`

const documentationText = css`
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 8px;
  white-space: pre-line;

  a > span {
    color: ${COLOR.PURPLE_01};
  }

  ul,
  ol {
    margin-top: 8px;
  }

  li {
    margin-bottom: 4px;
    line-height: 1.4;
  }
`
